import { gql } from "@apollo/client";

export const generateOrganizationUserOTP = gql`
mutation generateOrganizationUserOTP($organization_id:ID, $unique_id:String, $mobile:String){
  organization_users{
    generateOrganizationUserOTP(organization_id:$organization_id, unique_id:$unique_id, mobile:$mobile)
  }
}
`

export const verifyOrganizationUserMutation = gql`
mutation verifyOrganizationUserMutation($organization_id:ID,$unique_id:String,$mobile:String,$code:String){
  organization_users{
    verifyOrganizationUser(
      organization_id:$organization_id,
    	unique_id: $unique_id,
      mobile:$mobile,
      code:$code
    )
  }
}`;