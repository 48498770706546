import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import "./tailwind.css";
import { ChakraProvider } from "@chakra-ui/react"
import { ApolloProvider } from '@apollo/client';

import { ApolloClient, InMemoryCache } from '@apollo/client';
import { GQL_ENDPOINT } from './configs/constants';

const client = new ApolloClient({
  uri: GQL_ENDPOINT,
  cache: new InMemoryCache()
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <ChakraProvider>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </ChakraProvider>
  </ApolloProvider>,
  document.getElementById('root')
);
