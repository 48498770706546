import { useToast } from "@chakra-ui/react";
import { useMutation } from '@apollo/client';
import { useState } from 'react';
import * as React from 'react';
import { generateOrganizationUserOTP, verifyOrganizationUserMutation } from "../../graphql/mutations";
import { DEFAULT_TOAST_OPTIONS, ORGANIZATION_ID } from "../../configs/constants";
import Logger from '../../utils/logger';
import ONGCLogo from '../../public/images/ongc-logo.png';
import ONGCCoverImage from '../../public/images/ongc-bg-2.jpg';
import GPlayImage from '../../public/images/gplay-btn.png';
import AppStoreImage from '../../public/images/appstore-btn.png';
import PoweredByOneZoey from '../../public/images/powered-by-onezoey.svg';


const logger = Logger.get();

export interface VerificationFormProps {

}

const VerificationForm: React.SFC<VerificationFormProps> = () => {

  const [mobile, setMobile] = useState<string>('')
  const [uniqueId, setUniqueId] = useState<string>('')
  const [otp, setOTP] = useState<string>('')
  const [isGetOTPTriggered, setIsGetOTPTriggered] = useState<boolean>(false)
  const [isVerified, setIsVerified] = useState<boolean>(false)
  const [mobileNumberValid, setMobileNumberValid] = useState(true)
  const [otpValid, setOtpValid] = useState(true)
  const [generateOTPMutation] = useMutation(generateOrganizationUserOTP)
  const [verifyOTPMutation] = useMutation(verifyOrganizationUserMutation)
  const toast = useToast()


  const getOTP = async () => {
    try {
      if (validateData()) {

        await generateOTPMutation({
          variables: {
            organization_id: ORGANIZATION_ID,
            unique_id: uniqueId,
            mobile: mobile
          }
        });
        setIsGetOTPTriggered(true);
        toast({
          title: 'OTP Sent',
          description: 'Please Check Your Phone',
          status: 'success',
          ...DEFAULT_TOAST_OPTIONS
        })
      }
    }
    catch (error) {
      logger.error('getOTP, Something Went Wrong: ', error)
      if (error?.message) {
        let errMessage = 'Something Went Wrong'
        if (typeof (error.message) === 'string') {
          errMessage = error.message
        }
        toast({
          title: 'Error',
          description: errMessage,
          status: 'error',
          ...DEFAULT_TOAST_OPTIONS
        })
      }
    }

  }

  const verifyOTP = async () => {
    try {
      if (validateOTP()) {
        await verifyOTPMutation({
          variables: {
            organization_id: ORGANIZATION_ID,
            unique_id: uniqueId,
            mobile: mobile,
            code: otp
          }
        })

        toast({
          title: 'Wohoo!',
          description: 'Your OneZoey User Has been created!',
          status: 'success',
          ...DEFAULT_TOAST_OPTIONS
        })
        setIsVerified(true)
      }
    }
    catch (error) {
      logger.error('verifyOTP, Something Went Wrong: ', error)
      if (error?.message) {
        let errMessage = 'Something Went Wrong'
        if (typeof (error.message) === 'string') {
          errMessage = error.message
        }
        toast({
          title: 'Error',
          description: errMessage,
          status: 'error',
          ...DEFAULT_TOAST_OPTIONS
        })
      }
    }
  }
  const validateData = () => {
    const mobileRegex = /^\d{10}$/
    const isMobileValid = mobileRegex.test(mobile)
    setMobileNumberValid(isMobileValid)
    return isMobileValid
  }
  const validateOTP = () => {
    const otpRegex = /^\d{4}$/
    const isOtpvalid = otpRegex.test(otp)
    setOtpValid(isOtpvalid)
    return isOtpvalid
  }
  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 border-2">
        <div className="flex-1 flex flex-col justify-center">
          <img className="h-32 w-auto self-center" src={ONGCLogo} />
          <div className="mx-auto w-full max-w-sm lg:w-96">
            <div>
              <h2 className="mt-6 text-3xl font-bold text-gray-900">
                ONGC
              </h2>
              {isVerified ?
                <div>
                  <p className="mt-2 text-sm text-gray-600 max-w">
                    Wohoo, You're All Set!
                  </p>
                  <p className="mt-2 text-xs text-gray-600 max-w">
                    Step 1: Download The OneZoey App For Android or iOS.
                  </p>
                  <p className="mt-2 text-xs text-gray-600 max-w">
                    Step 2: Login Using Your Mobile Number.
                  </p>
                  <p className="mt-2 text-xs text-gray-600 max-w">
                    Step 3: Search for ONGC Health Institute.
                  </p>
                  <p className="mt-2 text-xs text-gray-600 max-w">
                    Step 4: Select your Physician and book E-Consultation.
                  </p>
                </div> :
                <p className="mt-2 text-sm text-gray-600 max-w">
                  Register For E-Consultation on OneZoey
                </p>}
            </div>

            <div className="mt-6">
              {isVerified ?
                <div className="flex justify-center px-4 mt-16">
                  <a
                    href="https://play.google.com/store/apps/details?id=com.onezoey"
                    target="_blank"
                  ><img className="h-14 w-42" src={GPlayImage}
                  ></img>
                  </a>
                  <a
                    href="https://apps.apple.com/in/app/onezoey/id1516192240"
                    target="_blank"
                  >   <img className="h-14 w-42 ml-4" src={AppStoreImage}></img>
                  </a>
                </div>
                :
                <div className="mt-6">
                  {
                    isGetOTPTriggered ?
                      <div>
                        <div className="space-y-2">
                          <p className="mt-2 text-sm text-gray-600 max-w cursor-pointer">
                            OTP has been sent to <span className="font-bold" onClick={() => { setIsGetOTPTriggered(false) }}> {mobile}</span>
                          </p>
                          <label className="block text-sm font-medium text-gray-700">
                            Enter OTP
                          </label>
                          <div className="mt-2">
                            <input
                              required
                              value={otp}
                              onChange={(event) => setOTP(event.target.value)}
                              placeholder="Type here"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-800 focus:border-red-800 sm:text-sm"
                            />
                            {
                              otpValid ? null :
                                <p className="mt-2 text-sm text-red-600" id="email-error">Invalid OTP.</p>
                            }
                          </div>
                        </div>
                      </div> :
                      <div>
                        <div>
                          <label className="block text-sm font-medium text-gray-700">
                            Employee Number
                          </label>
                          <div className="mt-2 mb-2">
                            <input
                              required
                              value={uniqueId}
                              onChange={(event) => setUniqueId(event.target.value)}
                              placeholder="Type here"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-800 focus:border-red-800 sm:text-sm"
                            />
                          </div>
                        </div>

                        <div className="space-y-2">
                          <label className="block text-sm font-medium text-gray-700">
                            Mobile Number
                          </label>
                          <div className="mt-2">
                            <input
                              required
                              value={mobile}
                              onChange={(event) => setMobile(event.target.value)}
                              placeholder="Type here"
                              className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-red-800 focus:border-red-800 sm:text-sm"
                            />
                            {
                              mobileNumberValid ? null :
                                <p className="mt-2 text-sm text-red-600" id="email-error">Invalid Mobile Number.</p>
                            }
                          </div>
                        </div>
                      </div>
                  }

                  <div>
                    {
                      isGetOTPTriggered ?
                        <button
                          onClick={() => verifyOTP()}
                          className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-900 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800">
                          Verify OTP
                        </button> :
                        <button
                          onClick={() => getOTP()}
                          className="w-full mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-red-900 hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-800">
                          Send OTP
                        </button>
                    }
                  </div>
                </div>
              }
            </div>
          </div>
        </div>
        <div className="self-center">
          <img width={200} src={PoweredByOneZoey} alt="Powered By OneZoey" />
        </div>

      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img className="absolute inset-0 h-full w-full object-cover"
          src={ONGCCoverImage}
          alt="Western Railyway Cover" />
      </div>
    </div>
  )
}

export default VerificationForm;
