import { Logger } from '../types/types';

export const LoggerLevels = {
  OFF: 0,
  ERROR: 1,
  WARN: 2,
  INFO: 3,
  DEBUG: 4,
  TRACE: 5,
};

let count = 0;

setTimeout(() => console.log('LoggerService instance count is ', count), 60 * 1000);

export class BaseLogger implements Logger {

  private _level = 3;
  private _prefix: string = '[APP]';

  constructor() {
    count += 1;
  }

  /**
   * Setlevel for logger
   * @param level LoggerLevels
   * @param logger Logger
   * Provide logger to set level of named logger
   * Else it will set level of global logger
   */
  public setLevel(level: number) {
    this._level = level;
    this.info('Setting log level on ', level);
  }

  public getLevel() {
    return this._level;
  }

  public setPrefix(prefix: string) {
    this._prefix = prefix;
  }

  public error(...msg: any) {
    if (this._level >= 1) {
      console.error(this._prefix, 'ERROR: ', ...msg);
    }
  }

  public warn(...msg: any) {
    if (this._level >= 2) {
      console.warn(this._prefix, 'WARN: ', ...msg);
    }
  }

  public info(...msg: any) {
    if (this._level >= 3) {
      console.info(this._prefix, 'INFO: ', ...msg);
    }
  }

  public debug(...msg: any) {
    if (this._level >= 4) {
      console.log(this._prefix, 'DEBUG: ', ...msg);
    }
  }

  public trace(...msg: any) {
    if (this._level >= 5) {
      console.log(this._prefix, 'TRACE: ', ...msg);
    }
  }
}

export default class LoggerService {
  private static logger: Logger;

  public static get(): Logger {
    if (!LoggerService.logger) {
      LoggerService.logger = new BaseLogger();
    }
    return LoggerService.logger;
  }
}
