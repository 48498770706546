import * as React from 'react';
import VerificationForm from '../components/Home/VerificationForm';
export interface HomeProps {
}

export interface HomeState {
}

class Home extends React.Component<HomeProps, HomeState> {
  render() {
    return (
      <VerificationForm />
    );
  }
}

export default Home;