import { ToastOptionsType } from "../types/types";

// DEV
// export const GQL_ENDPOINT = "http://localhost:1133/graphql";
// export const ORGANIZATION_ID = '8d837524-e2c3-4b22-8964-4230617499cd'

// PROD
export const GQL_ENDPOINT = 'https://v111.api.onezoey.com/graphql';
export const ORGANIZATION_ID = 'e23a7f4f-1085-49d4-8dbf-d5b4fac644c3'

export const DEFAULT_TOAST_OPTIONS: ToastOptionsType = {
  duration: 3000,
  isClosable: true,
  position: "top-right"
}